<template>
  <el-table-column
    :class-name="columnConfig.className"
    :label-class-name="columnConfig.labelClassName"
    :prop="columnConfig.prop"
    :label="columnConfig.label"
    :width="columnConfig.width"
    :align="columnConfig.align"
    :min-width="columnConfig.minWidth"
    :fixed="columnConfig.fixed"
    :sortable="sortable"
  >
    <template
      v-if="columnConfig.template || filters"
      v-slot="scope"
    >
      <component
        v-if="columnConfig.template"
        :is="columnConfig.template"
        :row="scope.row"
        v-bind="columnConfig.getBind(thisComponent, scope)"
        v-on="columnConfig.getVon(thisComponent, scope)"
      ></component>

      <div v-if="filters">
        {{format($companyConfiguration.helpers.getObjectPropByString(scope.row, filters[0]), filters[1])}}
      </div>
    </template>
  </el-table-column>
</template>

<script>

import ClickToEdit from "@/components/ClickToEdit";
import Vue from "vue";

export default {
  name: 'base-table-column',
  components: {ClickToEdit},

  props: {
    columnConfig: {type: Object, required: true},
    sortable: {},
  },
  data() {
    return {
      thisComponent: this,
    }
  },
  computed: {
    filters(){
      if( !this.columnConfig.prop ){
        return null;
      }
      let filter = this.columnConfig.prop.split('|');
      return filter[1] ? filter : null;
    }
  },
  methods: {

    elementsChanged(){
      this.$emit('elements-changed');
    },

    format(value, filter){
      return Vue.filter(filter)(value);
    }
  }
}

</script>

<style>

</style>
